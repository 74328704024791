<template>
  <div>
    <div class="hero is-fullheight">
      <div class="hero-body">
        <div class="container has-text-centered">
          <p class="title is-2">{{ title }}</p>
          <p class="subtitle is-5 mt-3 has-text-grey" v-html="message"></p>
          <div class="buttons is-centered is-4 mt-6" v-if="links">
            <a class="is-size-6 mx-3" v-for="link in links" :key="link.label" :href="link.to">{{ link.label }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      title: "ページが見つかりません",
      message: "お探しのページは、削除またはURLが変更された可能性があります。",
      links: [
        { to: "/", label: "トップへ" }
      ],
    }
  },
  created() { 
    switch (this.$route.query.code) {
      case 'unauthorized':
        this.title = "ページが利用できません"
        this.message = "リンクに問題があるか、アクセスする権限がありません。"
        this.links = [
          { to: "/", label: "トップへ" },
        ]
    }
  }
}
</script>
